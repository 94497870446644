import React from "react"
import icon from "../images/Shapes.webp"
import { Link } from "react-scroll";
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
      <main
        className="bg-banner  bg-cover md:bg-cover bg-no-repeat lg:h-[60vh] md:h-[50vh] sm:h-[60vh] h-[40vh]"
      >
        <div className="container flex items-center lg:h-[60vh] md:h-[50vh] sm:h-[60vh] h-[40vh]">
          <div className="lg:w-7/12 md:w-7/12 sm:w-7/12 w-full">
            <h2 className=" sm:text-4xl md:text-4xl lg:text-4xl text-2xl mt-12 font-semibold sm:mt-0 lg:mt-0 mb-4 sm:mb-2 banner-text">
            404: Not Found
            </h2>
            <p className="sm:text-md md:text-md lg:text-md text-md text-white">You just hit a route that doesn&#39;t exist... the sadness.</p>
            <div>
            <Link className="flex items-center text-white text-md py-5 cursor-pointer"  to="footer"
     spy={true}
     smooth={true}
     href="#"
     duration={500}>
              Scroll Down
              <img src={icon} className='ml-3 w-8 h-8' alt="saas development company"/>
            </Link>
            </div>
          </div>
        </div>
      </main>
   
  </Layout>
)

export default NotFoundPage
